import './styles/app.css';

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import glaze from "glazejs";

window.Splide = Splide;
Alpine.plugin(intersect)
window.Alpine = Alpine
Alpine.start()
gsap.registerPlugin(ScrollTrigger);

glaze({
    lib: {
      gsap: {
        core: gsap,
      },
    },
});